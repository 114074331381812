<template>
  <div id="issue">
    <div class="header">
      <van-row gutter="10">
        <!-- <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />
        </van-col> -->

        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />培训详情
        </van-col>
        <van-col
          span="14"
          class="header-title"
          @click="traing_sign"
          style="text-align: right"
        >
          {{ notification.is_sign ? "已签到" : "签到" }}
        </van-col>
      </van-row>
    </div>
    <div class="issue-detail">
      <div class="detail">
        <div class="detail-title">{{ notification.title }}</div>
        <p class="content" v-html="notification.content"></p>
        <van-row>
          <van-col span="12" class="date" style="text-align: left">{{
            notification.creator_name
          }}</van-col>
          <van-col span="12" class="date">{{
            notification.created_at
          }}</van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "train-item",
  data() {
    return {
      notification: {
        notification_type: 3,
      },
      unread_users: [],
      relation: {},
      active: 1,
      readPng: require("../../assets/images/read.png"),
      id: "",
    };
  },
  methods: {
    onClickLeft() {
      // this.$router.go(-1);
      this.$router.replace("/trainManage");
    },
    async get_notification(id) {
      const res = await this.$apis.get_traings_mes(id);
      if (res.data.status == "xia_jia") {
        Toast("该培训已下架！");
        setTimeout(() => {
          this.$router.replace("/trainManage");
        }, 500);
      } else {
        this.notification = res.data;
      }
    },
    // 已读方法
    async read_notification(id) {
      const res = await this.$apis.read_notification(id);

      // this.notification = res.notification
    },
    //签到
    async traing_sign() {
      const id = this.id;
      const data = {
        id: id,
      };
      const res = await this.$apis.traing_sign(id, data);
      this.get_notification(id);
    },
  },
  components: {},
  created() {
    const id = this.$route.query.id;
    this.id = id;
    if (id) {
      this.get_notification(id);
    }
  },
};
</script>

<style scoped>
#issue {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.issue-detail {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.detail {
  /* min-height: 80vh; */
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.4rem;
}
.detail-title {
  text-align: center;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.content {
  font-size: 14px;
  color: #666666;
  text-align: justify;
  margin-top: 0.5rem;
  word-wrap: break-word;
}
.date {
  font-size: 14px;
  color: #666666;
  text-align: right;
  margin-top: 0.5rem;
}
.unread-content {
  /* height: 80vh;
  overflow-y: scroll; */

  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgb(182 179 179 / 54%);
  border-radius: 16px;
  padding: 0rem 0.2rem;
  padding-bottom: 0.2rem;
}
.issue-sub-title {
  color: #333;
  font-size: 0.3rem;
  font-weight: bold;
  height: 1rem;
  line-height: 1rem;
}
.content-text {
  font-size: 14px;
  color: #666666;
  text-align: justify;
}
.unread-item {
  /* margin-bottom: 10px; */
  /* background: #fff; */
  padding: 0.1rem 0.3rem;
}
.unread-item:nth-child(even) {
  background: #f4f3f3 !important;
}
</style>
